<div class="container">
  <div class="d-flex-sp-btw header" >
    <img class="logo" src="assets/images/hyrox_logo1.png" height="160" width="10px">
    <h2 class="txt-white text-center"> Weight Measurement</h2>
    <div class="d-flex date">
      <h3 class="mr-25">{{todayDate | date:'dd-MM-yyyy'}}</h3>
      <h3 class="">{{ now }}</h3>
    </div>
  </div>
    <div class="no-data-sec text-center" *ngIf="details?.weightStatus != 200">
      <img src="assets/images/weight.png" width="15%" />
      <p *ngIf="details == null || details == undefined">Scan your fingerprint</p>
      <p *ngIf="details?.weightStatus == 300">Your Dand has Not Been Properly  Registered </p>
      <p *ngIf="details?.weightStatus == 400">Please scan your fingerprint then check Weight</p>
      <p *ngIf="details?.weightStatus == 600">Error Code - EWMS1</p>
      <p *ngIf="details?.weightStatus == 700">Error Code - EWMS2</p>
    </div>
    
    <div class="data-sec text-center" *ngIf="details?.weightStatus === 200">
      <table style="width: 100%; table-layout: fixed;">
        <tr>
          <td style="width: 33%;">
            <div class="text-center">
              <!-- <img *ngIf="details?.memberId !== 0" class="userimg"
                src="({{ profileImg }})" /><br> -->
                <!-- <img *ngIf="details?.memberId !== 0 && details?.memberImage" class="userimg" 
                src="{{ (profileImg !== null && profileImg !== undefined && profileImg !== '' && profileImg !== 'null' && profileImg !== 'undefined') ? profileImg : 'assets/images/avartar.jpg'}}" /><br>
                <img *ngIf="!details?.memberImage" src="{{ profileImg }}"/> -->
                <img *ngIf="primaryImageLoaded && details?.memberId !== 0 && details?.memberImage" src="{{logo}}" alt="Img" (error)="handleImageError()" width="180" style="margin: 0 auto;">
                <img *ngIf="!primaryImageLoaded && details?.memberId !== 0 && details?.memberImage" src="assets/images/avartar-removebg-preview.png" alt="Alternative Logo" width="180" style="margin: 0 auto;">
                <!-- <img *ngIf="details?.memberId !== 0" src="assets/images/avartar-removebg-preview.png" width="180" style="margin: 0 auto;"/> -->
                <p class="txt-white membername ellipses"><strong>{{details?.memberName}}</strong></p>
              <p class="memberid">{{details?.memberIdNo}}</p>
              <!-- <p class="memberWeight"> {{details?.memberWeight}} </p> -->
            </div>
          </td>
          <td style="width: 33%;">
            <div id="chart">

              <apx-chart  *ngIf="details?.memberId !== 0" [series]="chartOptions.series" [chart]="chartOptions.chart"
                [plotOptions]="chartOptions.plotOptions" [labels]="chartOptions.labels" [fill]="chartOptions.fill">
              </apx-chart>

            </div>
          </td>
          <!-- <td style="width: 33%;">
          </td> -->
          <td style="width: 33%;">
            <div id="bmichart">

              <apx-chart  *ngIf="details?.memberId !== 0" [series]="bmichartOptions.series" [chart]="bmichartOptions.chart"
                [plotOptions]="bmichartOptions.plotOptions" [labels]="bmichartOptions.labels"
                [fill]="bmichartOptions.fill">
              </apx-chart>

            </div>
          </td>
        </tr>
        <tr>
          <td style="width: 33%;">
            <div class="text-center">
              <img  *ngIf="details?.memberId !== 0" class="activity-icon"
                src="assets/images/body_fat.png" />
                <br>
              <p class="txt-white membername ellipses"><strong  *ngIf="details?.memberId !== 0">{{details?.bfp}}</strong></p>
              <p  *ngIf="details?.memberId !== 0" class="memberid header-text-value">Body Fat (%)</p>

            </div>
          </td>
          <td style="width: 33%;">
            <div class="text-center">

              <img  *ngIf="details?.memberId !== 0" class="activity-icon"
                src="assets/images/metabolism.png" />
                <br>
              <p class="txt-white membername ellipses"><strong  *ngIf="details?.memberId !== 0">{{details?.bmr}}</strong></p>
              <p class="memberid header-text-value"  *ngIf="details?.memberId !== 0">Basal Metabolic Rate (BMR)</p>

            </div>
          </td>
          <td style="width: 33%;">
            <div class="text-center">

              <img  *ngIf="details?.memberId !== 0" class="activity-icon"
                src="assets/images/water.png" />
                <br>
              <p  *ngIf="details?.memberId !== 0" class="txt-white membername ellipses header-text"><strong>{{details?.bwp}}</strong></p>
              <p  *ngIf="details?.memberId !== 0" class="memberid header-text-value">Body Water (L)</p>

            </div>
          </td>
        </tr>
      </table>

    </div>
    <div class="petal">
      <p>Powered by <span class="automations">Petals Automation</span></p>
    </div>

</div>

<div class="chart">
<apx-chart  *ngIf="details?.memberId === 0 && details?.weightStatus === 200" [series]="chartOptions.series" [chart]="chartOptions.chart"
[plotOptions]="chartOptions.plotOptions" [labels]="chartOptions.labels" [fill]="chartOptions.fill">
</apx-chart>
</div>