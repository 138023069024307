
export const environment = {
  production: false,
  // apiURL: "https://api.sparkfitness.fit/api/V1/GP/",
  apiURL: "https://api.phoenixcrosfit.com/api/V1/GP/",
  image:'http://api.hyroxfitnessclub.com/member/',
  mqttConfig: {
    hostname: "device.hyroxfitnessclub.com",
    port: 8084,
    path: "/mqtt",
    protocol: "wss",
    username : "mechnix"  ,
    password : "Mech#2023"
  }
};

